export default class Page {
    constructor() {
        // Page
        const btn = document.querySelector('.iframe__copy');
        if (btn) {
            btn.addEventListener('click', (e) => {
                function listener(e) {
                    e.clipboardData.setData('text/html', document.querySelector('.iframe').contentWindow.document.documentElement.innerHTML);
                    e.clipboardData.setData('text/plain', document.querySelector('.iframe').contentWindow.document.documentElement.innerHTML);
                    e.preventDefault();
                }
                // document.addEventListener('copy', listener);
                // document.execCommand('copy');
                // document.removeEventListener('copy', listener);

                const input = document.querySelector('.form__copyInput');
                if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
                    // handle iOS devices
                    const oldContentEditable = input.contentEditable;
                    const oldReadOnly = input.readOnly;
                    const range = document.createRange();

                    input.contentEditable = true;
                    input.readOnly = false;
                    range.selectNodeContents(input);

                    var s = window.getSelection();
                    s.removeAllRanges();
                    s.addRange(range);

                    input.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.

                    input.contentEditable = oldContentEditable;
                    input.readOnly = oldReadOnly;
                } else {
                    // other devices are easy
                    input.select();
                }
                // document.execCommand('copy');
                document.addEventListener('copy', listener);
                document.execCommand('copy');
                document.removeEventListener('copy', listener);
            });
        }
    }
}